import {
    Dashboard,
    MyGoals,
    MyDirectReports,
    Announcements,
    EnphaseGoals,
    DelegateGoals,
    Reports,
    ReviewCycle,
    AddAnnouncements,
    UserTabs,
    AdminTabs,
    ExecutiveDashboard,
    MyExecutiveReports,
    MyAPRFeedback,
    MyReportsFeedback,
} from './pages';
import { ComponentType } from 'react';
import {
    DashboardSvg,
    AnnouncementsSvg,
    MyDirectReportsSvg,
    MyGoalsSvg,
    ReportsSvg,
    Settings,
    ReviewCycleSvg,
    UsersSvg,
    SearchSvg,
    DelegateSvg,
    AnnouncementSettingSvg,
    AdminSvg,
    MyFeedback,
    ReportsFeedback,
} from './icons';
import { IRole } from './interfaces/Common';
interface PageRoutesInterface {
    path: string;
    component: React.FC;
    name: string;
    eventKey: string;
    icon: ComponentType;
    role: IRole[];
    children: PageRoutesInterface[];
}

const PageRoutes: PageRoutesInterface[] = [
    {
        path: '/dashboard',
        component: Dashboard,
        name: 'Dashboard',
        eventKey: 'dashboard',
        icon: DashboardSvg,
        role: ['EMPLOYEE', 'MANAGER', 'HR'],
        children: [],
    },
    {
        path: '/my-goals',
        component: MyGoals,
        name: 'My goals',
        eventKey: 'my-goals',
        icon: MyGoalsSvg,
        role: ['EMPLOYEE', 'MANAGER', 'HR'],
        children: [],
    },
    {
        path: '/my-direct-reports',
        component: MyDirectReports,
        name: 'My direct reports',
        eventKey: 'my-direct-goals',
        icon: MyDirectReportsSvg,
        role: ['MANAGER'],
        children: [],
    },
    {
        path: '/delegate-goals',
        component: DelegateGoals,
        name: 'Delegate goals',
        eventKey: 'delegate-goals',
        icon: DelegateSvg,
        role: ['MANAGER', 'EMPLOYEE', 'HR', 'EXECUTIVE'],
        children: [],
    },
    {
        path: '/enphase-goals',
        component: EnphaseGoals,
        name: 'Search goals',
        eventKey: 'enphase-goals',
        icon: SearchSvg,
        role: ['EMPLOYEE', 'MANAGER', 'HR'],
        children: [],
    },
    {
        path: '/apr-feedback/my-apr-feedback',
        component: MyAPRFeedback,
        name: 'My APR',
        eventKey: 'apr-feedback',
        icon: MyFeedback,
        role: ['EMPLOYEE', 'MANAGER', 'HR'],
        children: [],
    },
    {
        path: '/apr-feedback/my-reports-feedback',
        component: MyReportsFeedback,
        name: 'My reports APR',
        eventKey: 'reports-feedback',
        icon: ReportsFeedback,
        role: ['MANAGER'],
        children: [],
    },
    {
        path: '/announcements',
        component: Announcements,
        name: 'Announcements',
        eventKey: 'announcements',
        icon: AnnouncementsSvg,
        role: ['EMPLOYEE', 'MANAGER', 'HR'],
        children: [],
    },
    {
        path: '/reports',
        component: Reports,
        name: 'Reports',
        eventKey: 'reports',
        icon: ReportsSvg,
        role: ['MANAGER', 'HR'],
        children: [],
    },
    {
        path: '/executive-dashboard',
        component: ExecutiveDashboard,
        name: 'Executive Dashboard',
        eventKey: 'dashboard',
        icon: DashboardSvg,
        role: ['EXECUTIVE'],
        children: [],
    },
    {
        path: '/my-executive-reports',
        component: MyExecutiveReports,
        name: 'My executive reports',
        eventKey: 'my-executive-reports',
        icon: MyDirectReportsSvg,
        role: ['EXECUTIVE'],
        children: [],
    },
    {
        path: '/settings/',
        component: ReviewCycle,
        name: 'Settings',
        eventKey: 'review-cycle',
        icon: Settings,
        role: ['HR', 'ADMIN'],
        children: [
            {
                path: '/settings/review-cycle',
                component: ReviewCycle,
                name: 'Review cycle',
                eventKey: 'review-cycle',
                icon: ReviewCycleSvg,
                role: ['HR', 'ADMIN'],
                children: [],
            },
            {
                path: '/settings/announcements',
                component: AddAnnouncements,
                name: 'Manage announcements',
                eventKey: 'add-announcements',
                icon: AnnouncementSettingSvg,
                role: ['HR', 'ADMIN'],
                children: [],
            },
            {
                path: '/settings/users',
                component: UserTabs,
                name: 'Manage users',
                eventKey: 'add-users',
                icon: UsersSvg,
                role: ['HR', 'ADMIN'],
                children: [],
            },
        ],
    },
    {
        path: '/admin',
        component: AdminTabs,
        name: 'Admin',
        eventKey: 'org',
        icon: AdminSvg,
        role: ['HR', 'ADMIN'],
        children: [],
    },
];

export default PageRoutes;
